import React from 'react';

export const techQuestions = [
    {
        question: 'What stack is the marketplace built with?',
        answer: (
            <div className='text-muted mb-0'>
                We use:
                <ul className='mb-0'>
                    <li>
                        <strong className='text-dark'>Solidity </strong>- building smart contracts
                    </li>
                    <li>
                        <strong className='text-dark'>Truffle suite </strong> – development environment, testing
                        framework, and asset pipeline for blockchains.
                    </li>
                    <li>
                        <strong className='text-dark'>Web3.js </strong> – JavaScript Library that allows interacting
                        with a local or remote Ethereum node
                    </li>
                    <li>
                        <strong className='text-dark'>IPFS </strong> – Server to save images with metadata in the
                        blockchain
                    </li>
                    <li>
                        <strong className='text-dark'>MetaMask </strong> – Crypto wallet for trading NFTs
                    </li>
                    <li>
                        <strong className='text-dark'>React.js </strong> – JavaScript framework to build the user
                        interface and connect everything together
                    </li>
                    <li>
                        <strong className='text-dark'>Bootstrap 5 </strong> – UI
                    </li>
                </ul>
            </div>
        ),
    },
    {
        question: 'What blockchains are supported?',
        answer: (
            <div className='text-muted mb-0'>
                Our products support these blockchains:
                <ul className='mb-0'>
                    <li>Binance Smart Chain</li>
                </ul>
            </div>
        ),
    },
    {
        question: 'What wallets are supported?',
        answer: 'At the moment, you can use MetaMask wallet.',
    },
    {
        question: 'More questions?',
        answer: (
            <p className='text-muted mb-0'>
                If you can’t find the answers here,{' '}
                <a
                    rel='noopener noreferrer'
                    href='https://nft.difi.market/contact/'
                    className='text-primary fw-bold'
                    target='_blank'
                >
                    get in touch
                </a>
                . We will be happy to help.
            </p>
        ),
    },
];

export const purchaseQuestions = [
    {
        question: 'How it Work?',
        answer: (
            <p className='text-muted mb-0'>
                You can create NFTs using Images, Video or Audio and sell them, or buy other existing NFTs.
You can also advertise your NFTs on the market.
            </p>
        ),
    },
    {
        question: 'DIFI NFT is Decentralized?',
        answer: (
            <>
                <p className='text-muted'>
                    Yes, probably one of the few totally decentralized Markets, there is no backend, no data is saved, everything happens through the Blockchain and Smart Contracts, and the administrative part works on the Blockchain!
And the files are saved on IPFS.
                </p>
            </>
        ),
    },
    {
        question: 'Exist any Fees?',
        answer: 'Yes, to maintain the marketplace 2.5% of each sale is paid to the staff.',
    },
    {
        question: 'More questions?',
        answer: (
            <p className='text-muted mb-0'>
                If you can’t find the answers here,{' '}
                <a
                    rel='noopener noreferrer'
                    href='https://nft.difi.market/contact'
                    className='text-primary fw-bold'
                    target='_blank'
                >
                    get in touch
                </a>
                . We will be happy to help.
            </p>
        ),
    },
];

export const licenseQuestions = [
    {
        question: 'More questions?',
        answer: (
            <p className='text-muted mb-0'>
                If you can’t find the answers here,{' '}
                <a
                    rel='noopener noreferrer'
                    href='https://nft.difi.market/contact'
                    className='text-primary fw-bold'
                    target='_blank'
                >
                    get in touch
                </a>
                . We will be happy to help.
            </p>
        ),
    },
];

export const supportQuestions = [
    {
        question: 'Do you provide support for your products?',
        answer: 'Yes, use email or contact form for any question.',
    },
    {
        question: 'More questions?',
        answer: (
            <p className='text-muted mb-0'>
                If you can’t find the answers here,{' '}
                <a
                    rel='noopener noreferrer'
                    href='https://nft.difi.market/contact'
                    className='text-primary fw-bold'
                    target='_blank'
                >
                    get in touch
                </a>
                . We will be happy to help.
            </p>
        ),
    },
];

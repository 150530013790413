import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from '@formspree/react';
import { settings } from '../../helpers/settings';

// HOOKS
import useMarketplace from '../../hooks/useMarketplace';

function Footer() {
    const marketplaceCtx = useMarketplace();
    const [state, handleSubmit] = useForm('xlezgplp');

    return (
        <footer className='footer bg-map bg-light mt-auto'>
            <div className='container py-5 z-index-20'>
                <div className='row gy-4 pt-4'>
                    <div className='col-lg-3 col-md-6 mb-lg-0'>
                        <img
                            src={
                                marketplaceCtx.themeMode === 'dark'
                                    ? settings.UISettings.logo
                                    : settings.UISettings.logoLight
                            }
                            alt='HashBit ART'
                            className='mb-3'
                            width='140'
                        />
                            <h2 className='h3 mb-1'>We are social</h2>
                            <p className='small text-muted mb-3'>
                                Follow us to keep updated.
                            </p>
                            <ul className='list-inline mb-0'>
                                <li className='list-inline-item'>
                                    <a className='social-link bg-hover-primary' rel='noreferrer' href='https://instagram.com/difimarket' target='_blank'>
                                        <i className='lab la-instagram'></i>
                                    </a>
                                </li>
                                <li className='list-inline-item'>
                                    <a className='social-link bg-hover-primary' rel='noreferrer' href='https://twitter.com/difimarket' target='_blank'>
                                        <i className='lab la-twitter'></i>
                                    </a>
                                </li>
                                <li className='list-inline-item'>
                                    <a className='social-link bg-hover-primary' rel='noreferrer' href='https://t.me/difitoken' target='_blank'>
                                        <i className='lab la-telegram'></i>
                                    </a>
                                </li>
                            </ul>
                    </div>
                    <div className='col-lg-3 col-md-6 mb-lg-0 pt-2'>
                        <h5 className='mb-4'>NFT</h5>
                        <ul className='list-unstyled text-muted mb-0'>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/'>
                                    Home
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/mint'>
                                    Create NFT
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/activity'>
                                    Activity
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='col-lg-3 col-md-6 mb-lg-0 pt-2'>
                        <h5 className='mb-4'>Community</h5>
                        <ul className='list-unstyled text-muted mb-0'>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/contact'>
                                    Contact
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/faq'>
                                    FAQ
                                </Link>
                            </li>
                            <li className='mb-2'>
                                <Link className='text-reset' to='/sellers'>
                                    Sellers
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='col-lg-3 col-md-6 pt-2'>
                        <h5 className='mb-4'>Newsletter</h5>
                        <p className='text-sm text-muted'>
                            Join the Newsletter and stay updated.
                        </p>
                        <form onSubmit={handleSubmit}>
                            <div className='input-group border border-2 border-gray-400 rounded-pill'>
                                <input
                                    className='form-control border-0 bg-none shadow-0 ps-4'
                                    type='email'
                                    name='email'
                                    autoComplete='off'
                                    placeholder='Enter your email address...'
                                />
                                <button
                                    className='btn btn-primary rounded-circle btn-sm shadow-0 m-2 icon-md'
                                    type='submit'
                                >
                                    <i className='las la-paper-plane'></i>
                                </button>
                            </div>
                        </form>
                        {state.succeeded ? (
                            <p className='bg-primary text-white mt-1 px-3 py-1 rounded-sm'>Thanks!</p>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='border border-gray-200 rounded-pill'></div>
            </div>

            <div className='container py-4 z-index-20'>
                <div className='row text-center'>
                    <p className='text-muted text-sm mb-0'>
                        © {new Date().getFullYear()} All rights reserved. Crafted by{' '}
                        <a
                            className='text-primary'
                            href='https://difi.market/'
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            Digital Files Token.
                        </a>
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;

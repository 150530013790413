import React from 'react';

function ContactInfo(props) {
    return (
        <div className={props.gridWidth}>
            <h5 className='h2 mb-5 text-center' data-aos='fade-up' data-aos-delay='100'>
                We are here to help you. Send us an email, join us on Slack or visit our office.
            </h5>
            <div className='row gy-3'>
                <div className='col-lg-12 d-flex' data-aos='fade-up' data-aos-delay='400'>
                    <div className='contact-icon bd-3 border-primary text-primary flex-shrink-0'>
                        <i className='las la-envelope'></i>
                    </div>
                    <div className='ms-3'>
                        <h6>Email address</h6>
                        <ul className='list-unstyled mb-0'>
                            <li>
                                <a
                                    className='text-decoration-none text-sm text-muted mb-1'
                                    rel='noreferrer'
                                    href='mailto:official@difi.market'
                                >
                                    hello@web3monkeys.com
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactInfo;

export const settings = {
    UISettings: {
        logo: '/images/logo_nft_white.png', // Logo of the dark version
        logoLight: '/images/logo_nft_black.png', // Logo of the light version
        marketplaceBrandName: 'DIFI | NFT Marketplace',
        marketplaceBrandDescription: 'Digital Files NFT Audio, Video, Image Marketplace',
        marketplaceAuthor: 'DIFI Developer',
        contactFormAddressId: 'moqzjqva',
        newsletterAddressId: 'xdovellz',
        usedNetworkName: 'Binance Smart Chain',
        heroImages: ['/cube-4.png', '/cube-3.png', '/cube-2.png'],
    },
    currency: 'DIFI',
    nativeCurrency: 'BNB',
    IPFSProjectID: '2JDq4rBw6OJd0z5wRzh6xRFMAIK',
    IPFSProjectSecret: '4746264f86e719944eecb393038ef5b7',
    rpcUrl: 'https://bsc-dataseed2.binance.org',
    networkId: 56,
    blockExplorerUrls: 'https://bscscan.com/',
    NFTmaxSize: 10024, // 10MB
    maxFeaturedItems: 10,
    IPFSGateway: 'hbit',
    royalties: [
        { label: 'None', value: 0 },
        { label: '5%', value: 5 },
        { label: '10%', value: 10 },
        { label: '15%', value: 15 },
        { label: '20%', value: 20 },
    ],
    saleCommission: 20, // 1000 represents 100%, e.g. 25 represents 2.5% etc...
};
